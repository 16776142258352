import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import "../../../admin/components/AdminConfirmationModal/AdminConfirmationModal.css";

const ChangeCostCenterModal = ({
  show,
  close,
  selectedUser,
  oldCostCenter,
  newCostCenter,
  handleConfirmChange,
}) => {
  return (
    <div>
      <Dialog
        open={show}
        onClose={close}
        sx={{
          backdropFilter: "blur(1px)",
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" sx={{ background: "white" }}>
          Confirm Action
        </DialogTitle>
        <DialogContent
          sx={{
            maxHeight: "calc(100% - 209px)",
            maxWidth: "40vw",
            minWidth: "28vw",
            color: "black",
            letterSpacing: "0.00938em",
            padding: "0px 24px",
          }}
        >
          <p>
            Are you sure you want to change the cost center for&nbsp;
            <b>{selectedUser?.name}?</b>
          </p>
          <div style={{ marginBottom: "30px" }}>
            <div
              style={{
                listStyle: "none",
                marginTop: "5 px",
                border: "1px solid lightgray",
                borderRadius: "3px",
                padding: "5px",
                paddingLeft: "10px",
                marginBottom: "5px",
                backgroundColor: "whitesmoke",
              }}
            >
              From <strong>{oldCostCenter.name}</strong> to{" "}
              <strong>{newCostCenter.name}</strong>
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <Stack
            sx={{
              padding: "0.2rem 0rem",
              margin: "0 auto",
              alignContent: "center",
              justifyContent: "center",
            }}
            direction="row"
            spacing={5}
          >
            <Button
              sx={{
                backgroundColor: "white",
                border: "1px solid gray",
                letterSpacing: "0.5px",
                padding: "4px 16px",
                borderRadius: "3px",
                fontSize: "14px",
                color: "black",
              }}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "green",
                letterSpacing: "0.5px",
                padding: "4px 16px",
                borderRadius: "3px",
                fontSize: "14px",
                color: "white",
                "&:hover": {
                  backgroundColor: "#015e01",
                  color: "white",
                },
              }}
              onClick={handleConfirmChange}
            >
              Confirm
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ChangeCostCenterModal;
