import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Swal from "sweetalert2";
import { INACTIVE_LOGOUT_TIMER_DURATION } from "utils/constants";

import "./sweetAlertCustom.css";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const InactiveLogout = ({ children }) => {
  const { accounts, instance } = useMsal();
  const sessionExpired = useSelector((state) => state.session.sessionExpired);
  let timer;

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleTimer();
      });
    });

    if (sessionExpired) {
      handleExpiredSession();
    }
  }, [accounts, sessionExpired]);

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const handleTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });

      handleExpiredSession();
    }, INACTIVE_LOGOUT_TIMER_DURATION);
  };

  const handleExpiredSession = async () => {
    if (accounts.length === 0) {
      // No user is logged in
    } else {
      Swal.fire({
        title: "Session Expired",
        text: "Your login session has expired. Please login again.",
        icon: "warning",
        confirmButtonColor: "#006161",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear();
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }
      });

      setTimeout(() => {
        localStorage.clear();
        instance.logoutRedirect({
          postLogoutRedirectUri: "/",
        });
      }, 10000);
    }
  };

  return children;
};

export default InactiveLogout;
